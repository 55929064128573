.partners-subscriptions {
  max-width: 1180px;
  margin: 42px auto;

  @media (width < 1180px) {
    margin: 42px 24px 0 24px;
  }

  .partners-subscriptions__body {
    .andes-carousel-snapped__container.andes-carousel-snapped__container--content {
      margin: 0;
      width: 100%;
    }

    .andes-carousel-snapped__container--pagination-position-top
      .andes-carousel-snapped {
      margin: 0;
      padding: 0;
    }

    .andes-carousel-snapped__pagination--position-top {
      width: max-content;
      margin: 0;
      align-self: center;
    }

    .andes-carousel-snapped__header {
      width: initial;
      margin-bottom: 17px;

      .section-header h2 + a,
      .section-header h2 + .ui-link {
        margin-left: 20px;
      }
    }

    .andes-carousel-snapped__container--content
      .andes-carousel-snapped__control--next {
      right: -52px;
    }
    .andes-carousel-snapped__container--content
      .andes-carousel-snapped__control--previous {
      left: -52px;
    }
  }

  .section-header {
    margin: 0;
    padding: 0px 10px 3px 0px;

    &__title {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .row-container {
    &--white {
      background-color: $andes-white;
      box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
      border-radius: 6px;
      padding: 20px;
    }
  }

  &__slide {
    width: 100%;

    &-gradient {
      position: absolute;
      height: 124px;
      width: 100%;
      bottom: 0;
      z-index: 1;
    }

    &-details-container {
      height: 100%;
      width: 100%;
    }

    &-background {
      height: 100%;
      width: 100%;
      position: absolute;
      object-fit: cover;
      object-position: top;
      display: block;

      &-color {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background-color: $andes-black;
      }

      &-only-element.partners-subscriptions__slide-background-only-element {
        position: absolute;
        right: 0;
        height: 100%;
        width: 50%;
        border-radius: 0 6px 6px 0;
        max-width: 560px;
      }
    }

    &-link {
      overflow: hidden;
      text-align: center;
      height: 250px;
      position: relative;
      display: block;
      border-radius: 6px;
    }

    &-ribbon {
      position: absolute;
      top: 16px;
      right: 16px;
      display: flex;
      align-items: center;
      z-index: 1;

      &-partner-name {
        color: $partner-name-color;
        font-size: 12px;
        margin-right: 8px;
      }
    }

    &-logo-text {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 16px;
      display: flex;
      align-items: center;
      width: 100%;
      z-index: 1;
      box-sizing: border-box;

      img.partners-subscriptions__slide-logo {
        width: 80px;
        height: 80px;
        object-fit: cover;
        display: flex;
        align-self: flex-end;
        border-radius: 6px;
        overflow: hidden;
      }

      &-only-element {
        left: 4%;
        top: 0;

        img.partners-subscriptions__slide-logo {
          align-self: center;
        }
      }
    }

    &-texts {
      color: #fff;
      flex: 1;

      &-line {
        display: block;
        text-align: left;
        margin-left: 16px;

        &--benefit {
          font-size: 22px;
          font-weight: 600;
          line-height: 1.09;
          @media (width <= 1186px) {
            font-size: 18px;
          }
        }

        &--partner-name {
          margin-top: 4px;
          font-size: 18px;
          line-height: 1.11;
          letter-spacing: 0.4px;
        }

        &--kicker {
          margin-bottom: 4px;
          font-size: 12px;
          line-height: 1.33;
          font-weight: 600;
        }
      }

      &-pill {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: 18px;
        border-radius: 6px;
        margin: 8px 0 0 16px;
        padding: 0 8px;

        &--text {
          font-size: 10px;
          font-weight: 600;
          line-height: 12px;
        }

        &--icon {
          margin-right: 6px;

          .ui-homes-icon {
            &--check {
              width: 8px;
              height: 10px;
            }

            &--chevron {
              width: 9px;
              height: 14px;
            }

            &--gift {
              width: 9px;
              height: 11px;
            }
          }
        }
      }
    }

    &-card-pill {
      position: absolute;
      z-index: 1;
      right: 0;

      .andes-badge__content {
        font-size: 12px;
      }
    }
  }
}

.partners-subscriptions-mobile {
  &--white {
    border-radius: 6px;
    background-color: $andes-white;
    margin: $andes-spacing-12 var(--new-home-inline-margin, 16px) 0;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 10%);
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0 16px;

    &--text {
      font-size: 18px;
      line-height: 1.2;
      margin: 12px 0;
      font-weight: 600;
    }
  }

  &__button {
    border-top: solid 1px #ededed;
    padding: 13px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ui-homes-icon--chevron {
      height: 14px;
      stroke: #3483fa;
      width: 9px;
    }

    &--text {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: #3483fa;
    }
  }

  &__thumbs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    padding: 8px;
    border-top: solid 1px #ededed;

    &--carousel {
      display: block;
      padding: 8px 0;

      .partners-subscriptions-mobile__thumbs__card {
        display: block;
        width: 100%;
        min-width: 110px;
      }

      .andes-carousel-free {
        margin-left: -$andes-spacing-24;
        margin-right: -$andes-spacing-24;
        padding: 0 $andes-spacing-32;

        &__list {
          width: auto;

          @media (width >= 630px) {
            width: 100%;
          }
        }

        &__slide {
          width: 100%;
        }
      }

      .andes-carousel-free
        .andes-carousel-free__list--spacing-12
        .andes-carousel-free__slide:not(:last-child) {
        margin-right: 8px;
      }
    }

    &--big {
      grid-template-columns: auto;

      .partners-subscriptions-mobile__thumbs__card {
        justify-content: flex-start;

        &-pill--icon {
          display: flex;
        }

        &-texts {
          text-align: left;

          &--title {
            padding: 0;
          }
        }
      }
    }

    &__card {
      text-decoration: none;
      position: relative;

      &-container {
        position: relative;
        overflow: hidden;
        height: 184px;
        border-radius: 6px;
        color: $andes-white;
      }

      &-details {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 34px;

        &--big {
          .partners-subscriptions-mobile__thumbs__card-texts {
            margin-top: 12px;
            text-align: center;
          }
        }

        &.partners-subscriptions-mobile__thumbs__card-details--big.partners-subscriptions-mobile__thumbs__card-details--withpill {
          margin-top: 96px;
        }
      }

      &-background {
        position: absolute;
        width: 100%;
        height: 100px;
        object-position: top;
        object-fit: cover;
      }

      &-logo {
        z-index: 1;
        border-radius: 6px;
      }

      &-gradient {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 124px;
        bottom: 0;
        border-radius: 0 0 6px 6px;
      }

      &-pill {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 24px;
        bottom: 0;
        border-radius: 0 0 6px 6px;

        &--text {
          font-size: 10px;
          font-weight: 600;
          line-height: 12px;
        }

        &--icon {
          display: none;
          margin-right: 6px;

          .ui-homes-icon {
            &--check {
              width: 8px;
              height: 10px;
            }

            &--chevron {
              width: 9px;
              height: 14px;
            }

            &--gift {
              width: 9px;
              height: 11px;
            }
          }

          @media (width >= 340px) {
            display: block;
          }
        }
      }

      &-texts {
        text-align: center;
        margin-top: 12px;
        z-index: 1;

        &--title,
        &--kicker,
        &--subtitle {
          display: block;
        }

        &--kicker {
          font-size: 9px;
          font-weight: 600;
          line-height: 14px;
        }

        &--title {
          font-size: 16px;
          line-height: 15px;
          font-weight: 600;
          padding: 0 7px;
          &__xl {
            font-size: 11px;
            padding: 0 4px;
            margin-bottom: 5px;
            line-height: 10px;
          }
        }

        &--subtitle {
          font-size: 12px;
          color: rgb(255 255 255 / 70%);
          line-height: $andes-spacing-12;
          padding-top: $andes-spacing-4;
        }
      }

      &-card-pill {
        position: absolute;
        z-index: 1;
        right: 0;
      }
    }
  }
}
